<template>
    <div class="popup-add-something">
        <div class="popup-add-something__wrapper">
            <Column align="center"
                    justify="center">
                <p class="popup-add-something__title">{{ $t('service.select_lang') }}</p>
                <Row class="popup-add-something__buttons"
                     :justify="$laptop ? 'around' : 'start'"
                     :wrap="$mobile">
                    <Column align="center">
                        <LocaleChanger />
                    </Column>
                </Row>
            </Column>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PopupChangeLang',
};
</script>

<style scoped>

</style>
